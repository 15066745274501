import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';

import { Route, Switch } from 'react-router';
import { BrowserRouter as Router } from 'react-router-dom';

import * as serviceWorker from './serviceWorker';

import Admin from './pages/Admin';
import Client from './pages/Client';

import { Typography } from '@material-ui/core';
const NotFound = () => (<Typography variant="body1" style={{ marginTop: 10, textAlign: 'center' }}>403</Typography>);

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Switch>
        <Route exact path="/admin" component={Admin} />
        <Route exact path="/c/:id">        
          <Client />
        </Route>
        <Route component={NotFound} />
      </Switch>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

serviceWorker.unregister();
