import React from 'react';
import logo from '../logo.png';

export default function Agreement({ isLoaded, userData, agreement }) {
  if(!isLoaded || userData === null || agreement === null) return (<p>загрузка...</p>);
  agreement = agreement
  .replaceAll('%dateStart%', new Date(userData.timestamp*1000).toLocaleDateString('ru-RU'))
  .replaceAll('%username%', userData.username)
  .replaceAll('%passport%', userData.passport)
  .replaceAll('%code%', userData.code)
  .replaceAll('%gettingDate%', userData.date)
  .replaceAll('%birthday%', userData.birthday)
  .replaceAll('%summ%', userData.summ)
  .replaceAll('%term%', userData.term)
  .replaceAll('%firstPayDate%', new Date(userData.timestamp*1000 + 31*86400*1000).toLocaleDateString('ru-RU'))
  .replaceAll('%dateEnd%', new Date(userData.timestamp*1000 + userData.term*31*86400*1000).toLocaleDateString('ru-RU') )
      {/* <img src={logo} style={{ width: 300, height: 'auto' }}/><br/> */}
  return (<div style={{ padding: 12 }} dangerouslySetInnerHTML={{ __html: agreement}}></div>);
}