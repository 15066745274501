import React, { useState } from 'react';
import { TextField, Grid, Button } from '@material-ui/core';
import AdminPanel from '../components/AdminPanel';
import { useHistory } from 'react-router'

export default function Admin() {
  const history = useHistory();

  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');

  const enterHandle = () => {
    localStorage.setItem('login', login);
    localStorage.setItem('password', password);
    history.go(0);
  }

  if((localStorage.getItem('login') === 'MAzEppaNeverSleep333') && (localStorage.getItem('password') === 'Jnvnn3ii1o')) {
    return <AdminPanel />
  }

  return <Grid
    container
    direction="row"
    justify="center"
    alignItems="center"
    style={{ height: '100vh' }}
  >
    <Grid item sm={4} xs={12}>
      <Grid container>
        <Grid item xs={12}>
          <TextField label="Логин" fullWidth value={login} onChange={ e => setLogin(e.target.value) } error={login.length < 3}/>
        </Grid>
        <Grid item xs={12}>
          <TextField label="Пароль" fullWidth value={password} onChange={ e => setPassword(e.target.value) } error={password.length < 3} />
        </Grid>
        <Grid item xs={12} style={{ paddingTop: 10 }}>
          <Button variant="contained" color="primary" fullWidth onClick={enterHandle}>
            Войти
          </Button>
        </Grid>
      </Grid>
    </Grid>
  </Grid>;
}